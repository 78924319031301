import {Component, Input, OnInit} from '@angular/core';
import { ApplicationStateService } from '../../services/application-state.service';
import { CurriculumYear } from '../../entity-system/entities/curriculum-year';
import { College } from '../../entity-system/entities/college';
import { CurriculumEntityType, Publishability } from '../../../api/datacleanuptool-api.model';
import {createToastObject, ToastService, ToastType} from "../../services/toast.service";
import {BsModalService} from "ngx-bootstrap";
import {VersionHistoryComponent} from "../../../authenticated/curriculum-year/version-history/version-history.component";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-curriculum-status',
  templateUrl: './curriculum-status.component.html',
  styleUrls: ['./curriculum-status.component.scss']
})
export class CurriculumStatusComponent implements OnInit {

  CurriculumEntityType = CurriculumEntityType;
  Publishability = Publishability;
  isCollapsed = true;

  constructor(private applicationState: ApplicationStateService,
              private toastService: ToastService,
              private modalService: BsModalService,) {

  }

  @Input() repressPreviewButton: boolean;

  ngOnInit() {

  }

  percentageOfActive(type: CurriculumEntityType, publishability: Publishability) {
    if (!this.curriculumYear.publishabilityCount) {return 0; }

    return this.curriculumYear.publishabilityCount(type, publishability) /
      ( this.curriculumYear.publishabilityCount(type, Publishability.CLEAN)
         + this.curriculumYear.publishabilityCount(type, Publishability.ERRORS)
         + this.curriculumYear.publishabilityCount(type, Publishability.WARNINGS)
      ) * 100;
  }

  get curriculumYear(): CurriculumYear {
    return this.applicationState.curriculumYear;
  }

  get college(): College {
    return this.applicationState.college;
  }

  archiveCurriculumYear() {
    this.curriculumYear.archive();
  }

  saveNotes(notes: string) {
    this.curriculumYear.updateNotes$(notes).subscribe(
      () => {
        this.toastService.add(
          createToastObject(ToastType.TOAST_IS_SUCCESS, 'Updated notes')
        );
      }
    );
  }

  showVersionHistory() {
    this.modalService.show(VersionHistoryComponent, {ignoreBackdropClick: true, backdrop: false, keyboard: false, class: 'modal-lg'});
  }
}
