import {SelectFormControlOption} from '../components/formcontrols/select/select.component';
import {AwardType} from "../../api/datacleanuptool-api.model";
import {awardTypeLabels} from '../../api/extensions';

export function humanReadableAwardType(awardType: AwardType){
  switch(awardType) {
    case AwardType.ASSOCIATE_IN_ARTS:{
      return "Associate in Arts";
      break;
    }
    case AwardType.ASSOCIATE_OF_ARTS:{
      return "Associate of Arts";
      break;
    }
    case AwardType.ASSOCIATE_IN_ARTS_FOR_TRANSFER:{
      return "Associate in Arts for Transfer";
      break;
    }
    case AwardType.ASSOCIATE_OF_ARTS_FOR_TRANSFER:{
      return "Associate of Arts for Transfer";
      break;
    }
    case AwardType.ASSOCIATE_IN_SCIENCE:{
      return "Associate in Science";
      break;
    }
    case AwardType.ASSOCIATE_OF_SCIENCE:{
      return "Associate of Science";
      break;
    }
    case AwardType.ASSOCIATE_IN_SCIENCE_FOR_TRANSFER:{
      return "Associate in Science for Transfer";
      break;
    }
    case AwardType.ASSOCIATE_OF_SCIENCE_FOR_TRANSFER:{
      return "Associate of Science for Transfer";
      break;
    }

    case AwardType.ASSOCIATE_IN_BIOLOGY_DIRECT_TRANSFER_AGREEMENT : {
      return "Associate in Biology DTA";
      break;
    }
    case AwardType.ASSOCIATE_IN_MATH_EDUCATION_DIRECT_TRANSFER_AGREEMENT : {
        return "Associate in Math Education DTA";
      break;
    }
    case AwardType.ASSOCIATE_IN_BUSINESS_DIRECT_TRANSFER_AGREEMENT : {
          return "Associate in Business DTA";
      break;
    }
    case AwardType.ASSOCIATE_IN_MUSIC_DIRECT_TRANSFER_AGREEMENT : {
            return "Associate in Music DTA";
      break;
    }
    case AwardType.ASSOCIATE_IN_PRE_NURSING_DIRECT_TRANSFER_AGREEMENT : {
              return "Associate in Pre-Nursing DTA";
      break;
    }
    case AwardType.ASSOCIATE_IN_NURSING_DIRECT_TRANSFER_AGREEMENT : {
                return "Associate in Nursing DTA";
      break;
    }
    case AwardType.ASSOCIATE_IN_BIOENGINEERING_AND_CHEMICAL_PRE_ENGINEERING_DIRECT_TRANSFER_AGREEMENT : {
                  return "Associate in Bioengineering and Chemical Pre-Engineering DTA";
      break;
    }
    case AwardType.ASSOCIATE_IN_COMPUTER_AND_ELECTRICAL_ENGINEERING_DIRECT_TRANSFER_AGREEMENT : {
                    return "Associate in Computer and Electrical Engineering DTA";
      break;
    }
    case AwardType.CERTIFICATE_OF_ACCOMPLISHMENT:{
      return "Certificate of Accomplishment";
      break;
    }
    case AwardType.CERTIFICATE_OF_ACHIEVEMENT:{
      return "Certificate of Achievement";
      break;
    }
    case AwardType.CERTIFICATE_OF_COMPETENCY:{
      return "Certificate of Competency";
      break;
    }
    case AwardType.CERTIFICATE_OF_COMPLETION:{
      return "Certificate of Completion (Noncredit)";
      break;
    }
    case AwardType.CERTIFICATE_OF_SPECIALIZATION:{
      return "Certificate of Specialization";
      break;
    }
    case AwardType.CERTIFICATE_OF_PERFORMANCE:{
      return "Certificate of Performance";
    }
    case AwardType.JOB_SKILLS_CERTIFICATE:{
      return "Job Skills Certificate";
      break;
    }
    case AwardType.SKILLS_CERTIFICATE:{
      return "Skills Certificate";
      break;
    }
    case AwardType.CAREER_CERTIFICATE:{
      return "Career Certificate";
      break;
    }
    case AwardType.NON_CREDIT:{
      return "Noncredit Certificate";
      break;
    }
    case AwardType.HIGH_SCHOOL_DIPLOMA:{
      return "High School Diploma"
    }
    case AwardType.PROFICIENCY_AWARD:{
      return "Proficiency Award";
      break;
    }
    case AwardType.BACHELOR_OF_SCIENCE:{
      return "Bachelor of Science";
      break;
    }
    case AwardType.BACHELOR_OF_ARTS:{
      return "Bachelor of Arts";
      break;
    }
    case AwardType.DOCTOR_OF_EDUCATION:{
      return "Doctor of Education";
      break;
    }
    case AwardType.MASTER_OF_ARTS:{
      return "Master of Arts";
      break;
    }
    case AwardType.MASTER_OF_BUSINESS_ADMINISTRATION:{
      return "Master of Business Administration";
      break;
    }
    case AwardType.MASTER_OF_PUBLIC_ADMINISTRATION:{
      return "Master of Public Administration";
      break;
    }
    case AwardType.MASTER_OF_SCIENCE:{
      return "Master of Science";
      break;
    }
    case AwardType.MASTER_OF_SOCIAL_WORK:{
      return "Master of Social Work";
      break;
    }
    case AwardType.EMPLOYABLE_SKILLS_CERTIFICATE: {
      return 'Employable Skills Certificate';
      break;
    }
    case AwardType.CERTIFICATE_OF_COMPLETION_CREDIT:{
      return 'Certificate of Completion (Credit)';
      break;
    }
    case AwardType.OTHER_CREDIT_AWARD:{
      return 'Other Credit Award';
      break;
    }

    case AwardType.CERTIFICATE_OF_PROFICIENCY:{
      return 'Certificate of Proficiency';
      break;
    }
    case AwardType.STATE_SHORT_EARLY_CHILDHOOD_EDUCATION_CERTIFICATE:{
      return 'State Short Early Childhood Education Certificate';
      break;
    }
    case AwardType.ASSOCIATE_IN_APPLIED_ARTS:{
      return 'Associate in Applied Arts';
      break;
    }
    case AwardType.ASSOCIATE_IN_FINE_ARTS:{
      return 'Associate in Fine Arts';
      break;
    }
    case AwardType.ASSOCIATE_IN_APPLIED_SCIENCE:{
      return 'Associate in Applied Science';
      break;
    }
    case AwardType.ASSOCIATE_IN_APPLIED_TECHNOLOGY:{
      return 'Associate in Applied Technology';
      break;
    }
    case AwardType.ASSOCIATE_IN_PRENURSING:{
      return 'Associate in Pre-Nursing';
      break;
    }
    case AwardType.BACHELOR_OF_APPLIED_SCIENCE:{
      return 'Bachelor of Applied Science';
      break;
    }
    case AwardType.CERTIFICATE: {
      return 'Certificate';
      break;
    }
    case AwardType.UNIVERSITY_OF_CALIFORNIA_TRANSFER_PATHWAY: {
      return 'University of California Transfer Pathway';
    }
    case AwardType.BACHELOR_OF_APPLIED_ARTS:
    case AwardType.BACHELOR_OF_SCIENCE_IN_NURSING:
    case AwardType.ASSOCIATE_IN_BUSINESS:
    case AwardType.ASSOCIATE_IN_MATH_EDUCATION:
    case AwardType.ASSOCIATE_IN_MUSIC:
    case AwardType.ASSOCIATE_IN_ARTS_AND_SCIENCES:
    case AwardType.ASSOCIATE_IN_ARTS_AND_SCIENCES_FOR_TRANSFER:
    case AwardType.ASSOCIATE_IN_TECHNICAL_ARTS:
    case AwardType.ASSOCIATE_OF_FINE_ARTS:
    case AwardType.ASSOCIATE_IN_SCIENCE_DEGREE_FOR_UC_TRANSFER:
    case AwardType.ASSOCIATE_IN_ARTS_DEGREE_FOR_UC_TRANSFER:
    case AwardType.SKILLS_RECOGNITION:
    case AwardType.ASSOCIATE_IN_APPLIED_SCIENCE_TRANSFER:
    case AwardType.ASSOCIATE_IN_APPLIED_SCIENCE_FOR_TRANSFER:
    case AwardType.ASSOCIATE_IN_ARTS_DIRECT_TRANSFER_AGREEMENT:
    case AwardType.ASSOCIATE_IN_ARTS_DTA:
    case AwardType.ASSOCIATE_IN_BIOLOGY_DTA_MRP:
    case AwardType.ASSOCIATE_IN_BUSINESS_DTA_MRP:
    case AwardType.ASSOCIATE_IN_COMPUTER_SCIENCE_DTA_MRP:
    case AwardType.ASSOCIATE_IN_PRE_NURSING_DTA_MRP:
    case AwardType.ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_1:
    case AwardType.ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_2:
    case AwardType.ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_2_MRP:
    case AwardType.SHORT_TERM_CERTIFICATE:
    case AwardType.CERTIFICATE_OF_ARTS_AND_SCIENCES:
    case AwardType.ASSOCIATE_OF_ARTS_DIRECT_TRANSFER_AGREEMENT:
    case AwardType.ASSOCIATE_IN_BIOLOGY_DIRECT_TRANSFER_AGREEMENT_MRP:
    case AwardType.ASSOCIATE_IN_BUSINESS_DIRECT_TRANSFER_AGREEMENT_MRP:
    case AwardType.ASSOCIATE_IN_MUSIC_DIRECT_TRANSFER_AGREEMENT_MRP:
    case AwardType.ASSOCIATE_OF_APPLIED_SCIENCE_FOR_TRANSFER:
    case AwardType.ASSOCIATE_IN_COMPUTER_SCIENCE_DIRECT_TRANSFER_AGREEMENT:
    case AwardType.ASSOCIATE_IN_PRE_NURSING_DIRECT_TRANSFER_AGREEMENT_MRP:
    case AwardType.ASSOCIATE_IN_NURSING_DIRECT_TRANSFER_AGREEMENT_MRP:
    case AwardType.ASSOCIATE_OF_APPLIED_SCIENCE:
    case AwardType.CERTIFICATE_OF_CAREER_PREPARATION:
    case AwardType.BACHELOR_OF_FINE_ARTS:
    case AwardType.BACHELOR_OF_MUSIC:
    case AwardType.BACHELOR_OF_APPLIED_BEHAVIORAL_SCIENCE:{
      return awardTypeLabels[awardType].label;
      break;
    }
    default: {
      return awardType;
      break;
    }
  }
}

export const AWARD_TYPES:  SelectFormControlOption[] = [
  { name: humanReadableAwardType(AwardType.CERTIFICATE), value: AwardType.CERTIFICATE},
  { name: humanReadableAwardType(AwardType.UNIVERSITY_OF_CALIFORNIA_TRANSFER_PATHWAY), value: AwardType.UNIVERSITY_OF_CALIFORNIA_TRANSFER_PATHWAY},
  { name: humanReadableAwardType(AwardType.BACHELOR_OF_SCIENCE), value: AwardType.BACHELOR_OF_SCIENCE },
  { name: humanReadableAwardType(AwardType.BACHELOR_OF_ARTS), value: AwardType.BACHELOR_OF_ARTS },
  { name: humanReadableAwardType(AwardType.DOCTOR_OF_EDUCATION), value: AwardType.DOCTOR_OF_EDUCATION },
  { name: humanReadableAwardType(AwardType.MASTER_OF_ARTS), value: AwardType.MASTER_OF_ARTS },
  { name: humanReadableAwardType(AwardType.MASTER_OF_BUSINESS_ADMINISTRATION), value: AwardType.MASTER_OF_BUSINESS_ADMINISTRATION },
  { name: humanReadableAwardType(AwardType.MASTER_OF_PUBLIC_ADMINISTRATION), value: AwardType.MASTER_OF_PUBLIC_ADMINISTRATION },
  { name: humanReadableAwardType(AwardType.MASTER_OF_SCIENCE), value: AwardType.MASTER_OF_SCIENCE },
  { name: humanReadableAwardType(AwardType.MASTER_OF_SOCIAL_WORK), value: AwardType.MASTER_OF_SOCIAL_WORK },
  { name: humanReadableAwardType(AwardType.CERTIFICATE_OF_ACHIEVEMENT), value: AwardType.CERTIFICATE_OF_ACHIEVEMENT },
  { name: humanReadableAwardType(AwardType.JOB_SKILLS_CERTIFICATE), value: AwardType.JOB_SKILLS_CERTIFICATE },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_ARTS), value: AwardType.ASSOCIATE_IN_ARTS },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_OF_ARTS), value: AwardType.ASSOCIATE_OF_ARTS },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_ARTS_FOR_TRANSFER), value: AwardType.ASSOCIATE_IN_ARTS_FOR_TRANSFER },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_OF_ARTS_FOR_TRANSFER), value: AwardType.ASSOCIATE_OF_ARTS_FOR_TRANSFER },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_BIOLOGY_DIRECT_TRANSFER_AGREEMENT), value: AwardType.ASSOCIATE_IN_BIOLOGY_DIRECT_TRANSFER_AGREEMENT},
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_MATH_EDUCATION_DIRECT_TRANSFER_AGREEMENT), value: AwardType.ASSOCIATE_IN_MATH_EDUCATION_DIRECT_TRANSFER_AGREEMENT},
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_BUSINESS_DIRECT_TRANSFER_AGREEMENT), value: AwardType.ASSOCIATE_IN_BUSINESS_DIRECT_TRANSFER_AGREEMENT},
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_MUSIC_DIRECT_TRANSFER_AGREEMENT), value: AwardType.ASSOCIATE_IN_MUSIC_DIRECT_TRANSFER_AGREEMENT},
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_PRE_NURSING_DIRECT_TRANSFER_AGREEMENT), value: AwardType.ASSOCIATE_IN_PRE_NURSING_DIRECT_TRANSFER_AGREEMENT},
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_NURSING_DIRECT_TRANSFER_AGREEMENT), value: AwardType.ASSOCIATE_IN_NURSING_DIRECT_TRANSFER_AGREEMENT},
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_BIOENGINEERING_AND_CHEMICAL_PRE_ENGINEERING_DIRECT_TRANSFER_AGREEMENT), value: AwardType.ASSOCIATE_IN_BIOENGINEERING_AND_CHEMICAL_PRE_ENGINEERING_DIRECT_TRANSFER_AGREEMENT},
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_COMPUTER_AND_ELECTRICAL_ENGINEERING_DIRECT_TRANSFER_AGREEMENT), value: AwardType.ASSOCIATE_IN_COMPUTER_AND_ELECTRICAL_ENGINEERING_DIRECT_TRANSFER_AGREEMENT},
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_SCIENCE), value: AwardType.ASSOCIATE_IN_SCIENCE },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_OF_SCIENCE), value: AwardType.ASSOCIATE_OF_SCIENCE },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_SCIENCE_FOR_TRANSFER), value: AwardType.ASSOCIATE_IN_SCIENCE_FOR_TRANSFER },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_OF_SCIENCE_FOR_TRANSFER), value: AwardType.ASSOCIATE_OF_SCIENCE_FOR_TRANSFER },
  { name: humanReadableAwardType(AwardType.CERTIFICATE_OF_ACCOMPLISHMENT), value: AwardType.CERTIFICATE_OF_ACCOMPLISHMENT},
  { name: humanReadableAwardType(AwardType.CERTIFICATE_OF_SPECIALIZATION), value: AwardType.CERTIFICATE_OF_SPECIALIZATION },
  { name: humanReadableAwardType(AwardType.CERTIFICATE_OF_COMPLETION), value: AwardType.CERTIFICATE_OF_COMPLETION },
  { name: humanReadableAwardType(AwardType.CERTIFICATE_OF_COMPETENCY), value: AwardType.CERTIFICATE_OF_COMPETENCY },
  { name: humanReadableAwardType(AwardType.CERTIFICATE_OF_PERFORMANCE), value: AwardType.CERTIFICATE_OF_PERFORMANCE},
  { name: humanReadableAwardType(AwardType.EMPLOYABLE_SKILLS_CERTIFICATE), value: AwardType.EMPLOYABLE_SKILLS_CERTIFICATE },
  { name: humanReadableAwardType(AwardType.SKILLS_CERTIFICATE), value: AwardType.SKILLS_CERTIFICATE },
  { name: humanReadableAwardType(AwardType.CAREER_CERTIFICATE), value: AwardType.CAREER_CERTIFICATE },
  { name: humanReadableAwardType(AwardType.CERTIFICATE_OF_COMPLETION_CREDIT), value: AwardType.CERTIFICATE_OF_COMPLETION_CREDIT},
  { name: humanReadableAwardType(AwardType.OTHER_CREDIT_AWARD), value: AwardType.OTHER_CREDIT_AWARD },
  { name: humanReadableAwardType(AwardType.PROFICIENCY_AWARD), value: AwardType.PROFICIENCY_AWARD },
  { name: humanReadableAwardType(AwardType.NON_CREDIT), value: AwardType.NON_CREDIT },
  { name: humanReadableAwardType(AwardType.HIGH_SCHOOL_DIPLOMA), value: AwardType.HIGH_SCHOOL_DIPLOMA},

  { name: humanReadableAwardType(AwardType.CERTIFICATE_OF_PROFICIENCY), value: AwardType.CERTIFICATE_OF_PROFICIENCY },
  { name: humanReadableAwardType(AwardType.STATE_SHORT_EARLY_CHILDHOOD_EDUCATION_CERTIFICATE), value: AwardType.STATE_SHORT_EARLY_CHILDHOOD_EDUCATION_CERTIFICATE },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_APPLIED_ARTS), value: AwardType.ASSOCIATE_IN_APPLIED_ARTS },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_FINE_ARTS), value: AwardType.ASSOCIATE_IN_FINE_ARTS },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_APPLIED_SCIENCE), value: AwardType.ASSOCIATE_IN_APPLIED_SCIENCE },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_APPLIED_TECHNOLOGY), value: AwardType.ASSOCIATE_IN_APPLIED_TECHNOLOGY },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_PRENURSING), value: AwardType.ASSOCIATE_IN_PRENURSING },
  { name: humanReadableAwardType(AwardType.BACHELOR_OF_APPLIED_SCIENCE), value: AwardType.BACHELOR_OF_APPLIED_SCIENCE },
  { name: humanReadableAwardType(AwardType.BACHELOR_OF_APPLIED_ARTS), value: AwardType.BACHELOR_OF_APPLIED_ARTS },
  { name: humanReadableAwardType(AwardType.BACHELOR_OF_SCIENCE_IN_NURSING), value: AwardType.BACHELOR_OF_SCIENCE_IN_NURSING },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_BUSINESS), value: AwardType.ASSOCIATE_IN_BUSINESS },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_MATH_EDUCATION), value: AwardType.ASSOCIATE_IN_MATH_EDUCATION },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_MUSIC), value: AwardType.ASSOCIATE_IN_MUSIC },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_ARTS_AND_SCIENCES), value: AwardType.ASSOCIATE_IN_ARTS_AND_SCIENCES },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_ARTS_AND_SCIENCES_FOR_TRANSFER), value: AwardType.ASSOCIATE_IN_ARTS_AND_SCIENCES_FOR_TRANSFER },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_TECHNICAL_ARTS), value: AwardType.ASSOCIATE_IN_TECHNICAL_ARTS },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_OF_FINE_ARTS), value: AwardType.ASSOCIATE_OF_FINE_ARTS },

  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_SCIENCE_DEGREE_FOR_UC_TRANSFER), value: AwardType.ASSOCIATE_IN_SCIENCE_DEGREE_FOR_UC_TRANSFER },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_ARTS_DEGREE_FOR_UC_TRANSFER), value: AwardType.ASSOCIATE_IN_ARTS_DEGREE_FOR_UC_TRANSFER },
  { name: humanReadableAwardType(AwardType.SKILLS_RECOGNITION), value: AwardType.SKILLS_RECOGNITION },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_APPLIED_SCIENCE_TRANSFER), value: AwardType.ASSOCIATE_IN_APPLIED_SCIENCE_TRANSFER },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_APPLIED_SCIENCE_FOR_TRANSFER), value: AwardType.ASSOCIATE_IN_APPLIED_SCIENCE_FOR_TRANSFER },
  { name: humanReadableAwardType(AwardType.SHORT_TERM_CERTIFICATE), value: AwardType.SHORT_TERM_CERTIFICATE },
  { name: humanReadableAwardType(AwardType.CERTIFICATE_OF_ARTS_AND_SCIENCES), value: AwardType.CERTIFICATE_OF_ARTS_AND_SCIENCES },
  { name: humanReadableAwardType(AwardType.BACHELOR_OF_APPLIED_BEHAVIORAL_SCIENCE), value: AwardType.BACHELOR_OF_APPLIED_BEHAVIORAL_SCIENCE },
  { name: humanReadableAwardType(AwardType.ASSOCIATE_IN_ARTS_DIRECT_TRANSFER_AGREEMENT), value: AwardType.ASSOCIATE_IN_ARTS_DIRECT_TRANSFER_AGREEMENT},

  {name: humanReadableAwardType(AwardType.ASSOCIATE_IN_ARTS_DTA), value: AwardType.ASSOCIATE_IN_ARTS_DTA},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_IN_BIOLOGY_DTA_MRP), value: AwardType.ASSOCIATE_IN_BIOLOGY_DTA_MRP},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_IN_BUSINESS_DTA_MRP), value: AwardType.ASSOCIATE_IN_BUSINESS_DTA_MRP},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_IN_COMPUTER_SCIENCE_DTA_MRP), value: AwardType.ASSOCIATE_IN_COMPUTER_SCIENCE_DTA_MRP},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_IN_PRE_NURSING_DTA_MRP), value: AwardType.ASSOCIATE_IN_PRE_NURSING_DTA_MRP},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_1), value: AwardType.ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_1},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_2), value: AwardType.ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_2},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_2_MRP), value: AwardType.ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_2_MRP},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_OF_APPLIED_SCIENCE),value: AwardType.ASSOCIATE_OF_APPLIED_SCIENCE},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_IN_NURSING_DIRECT_TRANSFER_AGREEMENT_MRP), value: AwardType.ASSOCIATE_IN_NURSING_DIRECT_TRANSFER_AGREEMENT_MRP},

  {name: humanReadableAwardType(AwardType.ASSOCIATE_OF_ARTS_DIRECT_TRANSFER_AGREEMENT), value: AwardType.ASSOCIATE_OF_ARTS_DIRECT_TRANSFER_AGREEMENT},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_IN_BIOLOGY_DIRECT_TRANSFER_AGREEMENT_MRP), value: AwardType.ASSOCIATE_IN_BIOLOGY_DIRECT_TRANSFER_AGREEMENT_MRP},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_IN_BUSINESS_DIRECT_TRANSFER_AGREEMENT_MRP), value: AwardType.ASSOCIATE_IN_BUSINESS_DIRECT_TRANSFER_AGREEMENT_MRP},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_IN_MUSIC_DIRECT_TRANSFER_AGREEMENT_MRP), value: AwardType.ASSOCIATE_IN_MUSIC_DIRECT_TRANSFER_AGREEMENT_MRP},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_OF_APPLIED_SCIENCE_FOR_TRANSFER), value: AwardType.ASSOCIATE_OF_APPLIED_SCIENCE_FOR_TRANSFER},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_IN_COMPUTER_SCIENCE_DIRECT_TRANSFER_AGREEMENT), value: AwardType.ASSOCIATE_IN_COMPUTER_SCIENCE_DIRECT_TRANSFER_AGREEMENT},
  {name: humanReadableAwardType(AwardType.ASSOCIATE_IN_PRE_NURSING_DIRECT_TRANSFER_AGREEMENT_MRP), value: AwardType.ASSOCIATE_IN_PRE_NURSING_DIRECT_TRANSFER_AGREEMENT_MRP},

  {name: humanReadableAwardType(AwardType.BACHELOR_OF_FINE_ARTS), value: AwardType.BACHELOR_OF_FINE_ARTS},
  {name: humanReadableAwardType(AwardType.BACHELOR_OF_MUSIC), value: AwardType.BACHELOR_OF_MUSIC},
  {name: humanReadableAwardType(AwardType.CERTIFICATE_OF_CAREER_PREPARATION), value: AwardType.CERTIFICATE_OF_CAREER_PREPARATION}






].sort((a, b) =>   {return a.name === b.name ? 0 : a.name < b.name ? - 1 : 1;});
