import {
  AwardType,
  AwardTypeSimilarity,
  InstructionMethod,
  PipelineEntityType,
  SubjectAndNumberSimilarity
} from './datacleanuptool-api.model';

export function pluralLabel(type: PipelineEntityType): string {
  switch (type) {
    case PipelineEntityType.COURSE:
      return 'Courses';

    case PipelineEntityType.PROGRAM:
      return 'Programs';
  }
}

export const instructionMethodOptions = [
  {name: 'Online', value: InstructionMethod.ONLINE},
  {name: 'On Site', value: InstructionMethod.ON_SITE},
  {name: 'Both online and onsite', value: InstructionMethod.BOTH}
];


export const awardTypeLabels: {[a in AwardType]: { label: string}} = {
  BACHELOR_OF_ARTS: {label: "Bachelor of Arts"},
  BACHELOR_OF_SCIENCE: {label: "Bachelor of Science"},
  DOCTOR_OF_EDUCATION: {label: "Doctor of Education"},
  MASTER_OF_ARTS: {label: "Master of Arts"},
  MASTER_OF_BUSINESS_ADMINISTRATION: {label: "Master of Business Administration"},
  MASTER_OF_PUBLIC_ADMINISTRATION: {label: "Master of Public Administration"},
  MASTER_OF_SCIENCE: {label: "Master of Science"},
  MASTER_OF_SOCIAL_WORK: {label: "Master of Social Work"},

  PROFICIENCY_AWARD : {label: 'Proficiency Award'},
  CERTIFICATE: {label: 'Certificate'},
  CERTIFICATE_OF_ACCOMPLISHMENT : {label: 'Certificate of Accomplishment'},
  CERTIFICATE_OF_SPECIALIZATION : {label: 'Certificate of Specialization'},
  CERTIFICATE_OF_COMPLETION : {label: 'Certificate of Completion (Noncredit)'},
  CERTIFICATE_OF_COMPETENCY : {label: 'Certificate of Competency'},
  CERTIFICATE_OF_PERFORMANCE : {label: 'Certificate of Performance'},
  JOB_SKILLS_CERTIFICATE : {label: 'Job Skills Certificate'},
  CERTIFICATE_OF_ACHIEVEMENT : {label: 'Certificate of Achievement'},
  ASSOCIATE_IN_ARTS : {label: 'Associate in Arts'},
  ASSOCIATE_IN_SCIENCE : {label: 'Associate in Science'},
  ASSOCIATE_OF_ARTS : {label: 'Associate of Arts'},
  ASSOCIATE_OF_SCIENCE : {label: 'Associate of Science'},
  ASSOCIATE_IN_ARTS_FOR_TRANSFER  : {label: 'A.A. for Transfer'},
  ASSOCIATE_OF_ARTS_FOR_TRANSFER  : {label: 'A.A. for Transfer'},
  ASSOCIATE_IN_SCIENCE_FOR_TRANSFER : {label: 'A.S. for Transfer'},
  ASSOCIATE_OF_SCIENCE_FOR_TRANSFER : {label: 'A.S. for Transfer'},
  ASSOCIATE_IN_BIOLOGY_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Biology DTA'},
  ASSOCIATE_IN_MATH_EDUCATION_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Math Education DTA'},
  ASSOCIATE_IN_BUSINESS_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Business DTA'},
  ASSOCIATE_IN_MUSIC_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Music DTA'},
  ASSOCIATE_IN_PRE_NURSING_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Pre-Nursing DTA'},
  ASSOCIATE_IN_NURSING_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Nursing DTA'},
  ASSOCIATE_IN_BIOENGINEERING_AND_CHEMICAL_PRE_ENGINEERING_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Bioengineering and Chemical Pre-Engineering DTA'},
  ASSOCIATE_IN_COMPUTER_AND_ELECTRICAL_ENGINEERING_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Computer and Electrical Engineering DTA'},
  UNIVERSITY_OF_CALIFORNIA_TRANSFER_PATHWAY: {label: 'University of California Transfer Pathway'},
  NON_CREDIT : {label: 'Noncredit'},
  EMPLOYABLE_SKILLS_CERTIFICATE: {label: 'Employable Skills Certificate'},
  CERTIFICATE_OF_COMPLETION_CREDIT:{label : 'Certificate of Completion (Credit)'},
  OTHER_CREDIT_AWARD: {label: 'Other Credit Award'},
  CERTIFICATE_OF_PROFICIENCY : {label: 'Certificate of Proficiency'},
  SKILLS_CERTIFICATE: {label: 'Skills Certificate'},
  CAREER_CERTIFICATE: {label: 'Career Certificate'},
  STATE_SHORT_EARLY_CHILDHOOD_EDUCATION_CERTIFICATE : {label: 'State Short Early Childhood Education Certificate'},
  ASSOCIATE_IN_APPLIED_ARTS : {label: 'Associate in Applied Arts'},
  ASSOCIATE_IN_FINE_ARTS : {label: 'Associate in Fine Arts'},
  ASSOCIATE_IN_APPLIED_SCIENCE : {label: 'Associate in Applied Science'},
  ASSOCIATE_IN_APPLIED_TECHNOLOGY : {label: 'Associate in Applied Technology'},
  ASSOCIATE_IN_PRENURSING : {label: 'Associate in Pre-Nursing'},
  BACHELOR_OF_APPLIED_SCIENCE : {label: 'Bachelor of Applied Science'},
  BACHELOR_OF_APPLIED_ARTS: {label: 'Bachelor of Applied Arts'},
  BACHELOR_OF_SCIENCE_IN_NURSING: {label: 'Bachelor of Science in Nursing'},
  ASSOCIATE_IN_BUSINESS: {label: 'Associate in Business'},
  ASSOCIATE_IN_MATH_EDUCATION: {label: 'Associate in Math Education'},
  ASSOCIATE_IN_MUSIC: {label: 'Associate in Music'},
  ASSOCIATE_IN_ARTS_AND_SCIENCES: {label: 'Associate in Arts and Sciences'},
  ASSOCIATE_IN_ARTS_AND_SCIENCES_FOR_TRANSFER: {label: 'Associate in Arts and Sciences for Transfer'},
  ASSOCIATE_IN_TECHNICAL_ARTS: {label: 'Associate in Technical Arts'},
  ASSOCIATE_OF_FINE_ARTS: {label: 'Associate of Fine Arts'},
  ASSOCIATE_IN_SCIENCE_DEGREE_FOR_UC_TRANSFER: {label: 'Associate in Science Degree for UC Transfer'},
  ASSOCIATE_IN_ARTS_DEGREE_FOR_UC_TRANSFER: {label: 'Associate in Arts Degree for UC Transfer'},
  ASSOCIATE_IN_ARTS_DIRECT_TRANSFER_AGREEMENT: {label: 'Associate in Arts Direct Transfer Agreement'},
  SKILLS_RECOGNITION: {label: 'Skills Recognition'},
  ASSOCIATE_IN_APPLIED_SCIENCE_TRANSFER: {label: 'Associate in Applied Science Transfer'},
  ASSOCIATE_IN_APPLIED_SCIENCE_FOR_TRANSFER: {label: 'Associate in Applied Science For Transfer'},
  SHORT_TERM_CERTIFICATE: {label: 'Short Term Certificate'},
  CERTIFICATE_OF_ARTS_AND_SCIENCES: {label: 'Certificate of Arts and Sciences'},
  BACHELOR_OF_APPLIED_BEHAVIORAL_SCIENCE: {label: 'Bachelor of Applied Behavioral Science'},
  ASSOCIATE_IN_ARTS_DTA: {label: "Associate In Arts DTA"},
  ASSOCIATE_IN_BIOLOGY_DTA_MRP: {label: "Associate in Biology DTA/MRP"},
  ASSOCIATE_IN_BUSINESS_DTA_MRP: {label:"Associate in Business DTA/MRP"},
  ASSOCIATE_IN_COMPUTER_SCIENCE_DTA_MRP: {label:"Associate in Computer Science DTA/MRP"},
  ASSOCIATE_IN_PRE_NURSING_DTA_MRP: {label:"Associate in Pre-Nursing DA/MRP"},
  ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_1: {label:"Associate of Science - Transfer Track 1"},
  ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_2: {label:"Associate of Science - Transfer Track 2"},
  ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_2_MRP: {label:"Associate of Science - Transfer Track 2/MRP"},
  ASSOCIATE_OF_ARTS_DIRECT_TRANSFER_AGREEMENT: {label:"Associate of Arts Direct Transfer Agreement"},
  ASSOCIATE_IN_BIOLOGY_DIRECT_TRANSFER_AGREEMENT_MRP: {label:"Associate in Biology Direct Transfer Agreement/MRP"},
  ASSOCIATE_IN_BUSINESS_DIRECT_TRANSFER_AGREEMENT_MRP: {label:"Associate in Business Direct Transfer Agreement/MRP"},
  ASSOCIATE_IN_MUSIC_DIRECT_TRANSFER_AGREEMENT_MRP: {label:"Associate in Music Direct Transfer Agreement/MRP"},
  ASSOCIATE_OF_APPLIED_SCIENCE_FOR_TRANSFER: {label:"Associate of Applied Science for Transfer"},
  ASSOCIATE_IN_COMPUTER_SCIENCE_DIRECT_TRANSFER_AGREEMENT: {label: "Associate in Computer Science Direct Transfer Agreement"},
  ASSOCIATE_IN_PRE_NURSING_DIRECT_TRANSFER_AGREEMENT_MRP: {label:"Associate in Pre-Nursing Direct Transfer Agreement/MRP"},
  ASSOCIATE_OF_APPLIED_SCIENCE: {label:"Associate of Applied Science"},
  ASSOCIATE_IN_NURSING_DIRECT_TRANSFER_AGREEMENT_MRP: {label:"Associate in Nursing Direct Transfer Agreement/MRP"},
  HIGH_SCHOOL_DIPLOMA: {label:"High School Diploma"},
  BACHELOR_OF_FINE_ARTS: {label:"Bachelor of Fine Arts"},
  BACHELOR_OF_MUSIC: {label:"Bachelor of Music"},
  CERTIFICATE_OF_CAREER_PREPARATION: {label:"Certificate of Career Preparation"}
};

export function labelForAwardType(awardType: AwardType) {
  return awardTypeLabels[awardType].label;
}

export function labelForAwardTypeSimilarity(similarity: AwardTypeSimilarity) {
  return awardTypeSimilarityLabels[similarity];
}

export const awardTypeSimilarityLabels: { [a in AwardTypeSimilarity]: string } = {
  DISSIMILAR: "Unrelated",
  SOMEWHAT_SIMILAR: "Related",
  VERY_SIMILAR: "Similar",
  SAME: "Same"
};

export function labelForSubjectAndNumberSimilarity(similarity: SubjectAndNumberSimilarity) {
  return subjectAndNumberSimilarityLabels[similarity];
}

export const subjectAndNumberSimilarityLabels: { [a in SubjectAndNumberSimilarity]: string } = {
  [SubjectAndNumberSimilarity.DISSIMILAR]: "Distinct",
  [SubjectAndNumberSimilarity.SOMEWHAT_SIMILAR]: "Somewhat Similar",
  [SubjectAndNumberSimilarity.VERY_SIMILAR]: "Similar",
  [SubjectAndNumberSimilarity.IDENTICAL_AS_SIMPILFIED]: "Very Similar",
};


/** Returns the bootstrap CSS class to indicate the match quality */
export function classForMatchQuality(matchQuality: number) {
  if (matchQuality >= 90) {
    return "badge-success";
  } else if (matchQuality >= 80) {
    return "badge-warning";
  } else {
    return "badge-danger";
  }
}

export function classForAwardTypeSimilarity(similarity: AwardTypeSimilarity) {
  if (similarity === AwardTypeSimilarity.SAME) {
    return "badge-success";
  } else if (similarity === AwardTypeSimilarity.VERY_SIMILAR) {
    return "badge-warning";
  } else if (similarity === AwardTypeSimilarity.SOMEWHAT_SIMILAR) {
    return "badge-warning";
  } else if (similarity === AwardTypeSimilarity.DISSIMILAR) {
    return "badge-danger";
  }
}

export function classForSubjectAndNumberSimilarity(similarity: SubjectAndNumberSimilarity) {
  if (similarity === SubjectAndNumberSimilarity.IDENTICAL_AS_SIMPILFIED) {
    return "badge-success";
  } else if (similarity === SubjectAndNumberSimilarity.VERY_SIMILAR) {
    return "badge-warning";
  } else if (similarity === SubjectAndNumberSimilarity.SOMEWHAT_SIMILAR) {
    return "badge-warning";
  } else if (similarity === SubjectAndNumberSimilarity.DISSIMILAR) {
    return "badge-danger";
  }
}

